import React from 'react';
import { Box } from '@bojagi/pablo/Box';
import { Container } from '../components/Container';
import { Feature, FeatureList } from '../components/FeatureList';
import openSourceImg from '../images/bulletpoint-open-source.svg';
import ghIntegrationImg from '../images/bulletpoint-gh-integration.svg';
import storybookImg from '../images/bulletpoint-storybook.svg';

export function KeyFeaturesDev() {
  return (
    <Container>
      <Box mb={[160, 270]}>
        <FeatureList>
          <Feature
            title="GitHub Integration"
            image={ghIntegrationImg}
            imageAltText="Laptop with Bojagi and GitHub Stickers"
          >
            Bojagi has been built specifically for GitHub integration. Collaborating with developers
            has never been this easy.
          </Feature>
          <Feature title="Storybook Support" image={storybookImg} imageAltText="Storybook logo">
            Already using Storybook? Get started in minutes! Enjoy seamless Storybook Stories
            integration.
          </Feature>
          <Feature
            title="Free for Open Source"
            image={openSourceImg}
            imageAltText="Open Source Logo"
          >
            Have an open source design system or landing page? Bojagi is free to use for you!
          </Feature>
        </FeatureList>
      </Box>
    </Container>
  );
}
