import React, { ReactNode } from 'react';
import { Box } from '@bojagi/pablo/Box';
import { Headline, Paragraph } from '@bojagi/pablo/Typography';
import bojagiLogo from '../images/bojagi-logo.svg';
import { ArrowButton } from '../components/ArrowButton';

export interface SimpleMastheadProps {
  headline: ReactNode;
  text: ReactNode;
  logoSuffix?: ReactNode;
}

export function SimpleMasthead({ headline, text, logoSuffix }: SimpleMastheadProps) {
  return (
    <Box width="100vw" position="relative">
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        pt={72}
        pb={220}
        maxWidth={800}
        px={6}
        mx="auto"
      >
        <Box mb={62} display="flex">
          <img id="bjg-intro-logo" alt="Bojagi Logo" height="72" src={bojagiLogo} />
          {logoSuffix}
        </Box>
        <Headline textAlign={['center', 'left']}>{headline}</Headline>
        <Paragraph fontSize="1.5rem" lineHeight={1.6} textAlign="center" mb={50}>
          {text}
        </Paragraph>
        <Box display="flex" flexDirection={['column', 'row']} mx={[0, -6]}>
          <ArrowButton to="/register" mx={[0, 6]} mb={[4, 0]}>
            Register for Free
          </ArrowButton>
          <ArrowButton to="/demo" variant="secondary" mx={[0, 6]} mb={[4, 0]}>
            Interactive Demo
          </ArrowButton>
        </Box>
      </Box>
    </Box>
  );
}
