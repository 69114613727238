import React from 'react';
import { Box } from '@bojagi/pablo/Box';
import { Title, Paragraph } from '@bojagi/pablo/Typography';
import { Image } from '@bojagi/pablo/Image';
import { TitleInfo } from '../../components/TitleInfo';
import illustrationImg from '../../images/illustration-visual-changes.svg';
import { Container } from '../../components/Container';

export function CompareVisualChanges() {
  return (
    <Container>
      <Box
        display="flex"
        flexDirection={['column', 'column', 'row']}
        justifyContent="center"
        alignItems="center"
        maxWidth={1100}
        mx="auto"
        mb={[180, 270]}
      >
        <Box maxWidth={430}>
          <Box>
            <TitleInfo>Compare visual changes</TitleInfo>
            <Title>Keep track of progress</Title>
          </Box>
          <Paragraph>
            Bojagi helps you to see what changed in the implementation of your design. Compare
            components with earlier versions to keep track of progress.
          </Paragraph>
          <Paragraph>
            You can also refer to them in comments if you liked something better in the past.
          </Paragraph>
          <Paragraph />
        </Box>
        <Image
          width={['100%', 440]}
          mt={[30, 30, 0]}
          ml={[0, 0, 80]}
          src={illustrationImg}
          alt="Visual before and after comparison of a component"
        />
      </Box>
    </Container>
  );
}
