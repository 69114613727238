import React from 'react';
import { Box } from '@bojagi/pablo/Box';
import { Title, Paragraph } from '@bojagi/pablo/Typography';
import { Image } from '@bojagi/pablo/Image';
import { TitleInfo } from '../../components/TitleInfo';
import illustrationImg from '../../images/illustration-gh-workflow.svg';
import { Container } from '../../components/Container';

export function WorkflowIntegration() {
  return (
    <Container>
      <Box
        display="flex"
        flexDirection={['column', 'column', 'row']}
        justifyContent="space-between"
        alignItems="center"
        maxWidth={1100}
        mx="auto"
        mb={[180, 270]}
      >
        <Image
          width={['100%', 490]}
          mr={[0, 0, 70]}
          mb={[50, 50, 0]}
          src={illustrationImg}
          alt="Examples of different workflow integrations like Continuous Integration and GitHub"
        />
        <Box maxWidth={550}>
          <Box>
            <TitleInfo>Integrate into your Workflow</TitleInfo>
            <Title>You know best how you work best</Title>
          </Box>
          <Paragraph>
            We know that you love your workflows. And we don’t want to change them: we adapt to you,
            not you to us.
          </Paragraph>
          <Paragraph>
            Bojagi is build on top of GitHub Pull Requests and works in any CI environment.
          </Paragraph>
          <Paragraph>
            Wanna be strict? Just require designer approval to merge pull requests. Or be casual and
            ping them in Slack, so they can see their designs in action.
          </Paragraph>
          <Paragraph />
        </Box>
      </Box>
    </Container>
  );
}
