import React from 'react';
import { Box } from '@bojagi/pablo/Box';
import { Title, Paragraph } from '@bojagi/pablo/Typography';
import { Image } from '@bojagi/pablo/Image';
import { TitleInfo } from '../../components/TitleInfo';
import illustrationImg from '../../images/illustration-gh-integration.svg';
import { Container } from '../../components/Container';

export function GhIntegration() {
  return (
    <Container>
      <Box
        display="flex"
        flexDirection={['column', 'column', 'row']}
        justifyContent="space-between"
        alignItems="center"
        maxWidth={1100}
        mx="auto"
        mb={[180, 270]}
      >
        <Box maxWidth={550}>
          <Box>
            <TitleInfo>Use the tools you love</TitleInfo>
            <Title>Get feedback in your GitHub PR</Title>
          </Box>
          <Paragraph>
            Feedback for your <strong>React</strong> components from your design team is waiting for
            you on GitHub. Designers can approve your PR visually and provide annotated feedback
            directly.
          </Paragraph>
          <Paragraph>
            Your pull request is the source of truth for feedback from fellow engineers and now
            designers.
          </Paragraph>
          <Paragraph />
        </Box>
        <Image
          width={['100%', 490]}
          mt={[30, 30, 0]}
          src={illustrationImg}
          alt="Someone's comment on a component from Bojagi is displayed on GitHub. There is a positive GitHub status check below it"
        />
      </Box>
    </Container>
  );
}
