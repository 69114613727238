import * as React from 'react';
import { Image } from '@bojagi/pablo/Image';
import { ShowAbove } from '@bojagi/pablo/Show';
import { Container } from '../components/Container';
import { Layout } from '../components/Layout';
import { Seo } from '../components/Seo';
import { ClosingBox } from '../sections/ClosingBox';
import { GhIntegration } from '../sections/GhIntegration';
import { IntroductionDev } from '../sections/IntroductionDev';
import { KeyFeaturesDev } from '../sections/KeyFeaturesDev';
import { CompareVisualChanges } from '../sections/CompareVisualChanges';
import { SimpleMasthead } from '../sections/SimpleMasthead';
import { WorkflowIntegration } from '../sections/WorkflowIntegration';
import logoSuffixImg from '../images/masthead-dev-suffix.png';

const IndexPage = () => (
  <Layout showLogo>
    <Seo
      title="The Developer Page"
      route="for-developers"
      socialPreviewImage="bojagi-social-preview-developer.jpg"
    />
    <SimpleMasthead
      headline="The Developer Page"
      text="Bojagi enables instant feedback on visual &amp; interactive web components 
so UI issues get resolved immediately."
      logoSuffix={
        <ShowAbove breakpoint="sm">
          <Image ml={20} width={168} src={logoSuffixImg} />
        </ShowAbove>
      }
    />
    <IntroductionDev />
    <KeyFeaturesDev />
    <GhIntegration />
    <WorkflowIntegration />
    <CompareVisualChanges />
    <Container>
      <ClosingBox title="Convinced?">
        Try Bojagi today and see how it works in action. We are free during the open Beta!
      </ClosingBox>
    </Container>
  </Layout>
);

export default IndexPage;
