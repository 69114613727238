import React from 'react';
import { Title, Paragraph } from '@bojagi/pablo/Typography';
import { LoggerContext } from '@bojagi/react-event';
import videoPlaceholderImg from '../images/video-placeholder-dev.jpg';
import { IntroductionVideo } from '../components/IntroductionVideo';
import { IntroductionContainer } from '../components/IntroductionContainer';

export function IntroductionDev() {
  return (
    <IntroductionContainer
      left={
        <>
          <Title>
            Streamline UI development,
            <br /> resolve UI Issues faster
          </Title>
          <Paragraph>
            Bojagi provides instant visual feedback on Web component implementation.
          </Paragraph>
          <Paragraph>
            Our tool works by extracting <strong>React</strong> components from your web app, and
            then presenting them in their full visual &amp; interactive state. When a component is
            new or has changed, we give designers early insights, so they can give feedback
            immediately.
          </Paragraph>
          <Paragraph>
            Everything is relayed to developers via GitHub Pull Requests so developers see all
            requested changes right away.
          </Paragraph>
        </>
      }
      right={
        <>
          <LoggerContext section="introduction">
            <IntroductionVideo
              title="A quick Bojagi tour"
              videoId="c4KaMzcP0II"
              placeholderImage={videoPlaceholderImg}
            />
          </LoggerContext>
        </>
      }
    />
  );
}
